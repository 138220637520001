$accent-color: rgb(0, 120, 216);
$secondary-color: rgba(0, 0, 0, 0.253);
$footer-height: 60px;

body{
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

#root{
  margin:0;
  position: relative;
  min-height: 100vh;
  padding-bottom: $footer-height;
}

h1{
  margin-top: 5px;
  text-align: center;
  font-size: xx-large;
  text-transform: uppercase;
  color: white !important;
  background-color: rgba($accent-color, .75);
  padding-top: 5px;
  padding-bottom: 5px;
}

div.App{
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
}

div.title{
  font-size: x-large;
  color: #ccc;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 1s ease-in-out;
  position: relative;
}

div.title:hover{
  content: attr(data-item);
  transition: all 1s ease-in-out;
  color: $accent-color;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  background-color: rgba($accent-color, .5);
  color: white;
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

header {
  height: auto;
}

nav {
  margin: 0;
  padding: 0;
}

nav.menuItems ul{
    list-style: none;
    display: flex;
}
nav.menuItems li {
    margin: 10px;
}
nav.menuItems li a {
        text-decoration: none;
        color: #8f8f8f;
        font-size: 24px;
        font-weight: 400;
        transition: all 0.5s ease-in-out;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
}

nav.menuItems li a:hover{
    content: attr(data-item);
    transition: 0.5s;
    color: $accent-color;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

footer {
  font-size: 12px;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  background-color: #f1f1f1;
  color: #000000;
  text-align: center;
  display: block;
  clear: both;
}

footer a {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid;
}
footer a:hover {
  border-bottom: 1px transparent;
}

div.actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

div.btn{
  flex: 0 0 auto;
  border: 1px solid $accent-color;
  border-radius: 5px;
  padding: 5px;
  background-color: $accent-color;
  clear:both;
  margin-bottom: 5px;
  text-align: center;
}

.btn-filter-group{
  display: flex;
  flex-wrap: wrap;
}

.btn-filter{
  background-color: white;
  border: 1px solid $secondary-color;
  border-radius: 5px;
  margin-bottom: 2px;
  flex-basis: 100px !important;
  flex-grow: 0  !important;
}

button.active{
  background-color: $accent-color;
  color: white;
}
.btn-group{
  margin-bottom: 5px;
}

div.btn a{
  text-decoration: none;
  color: white;
}

pre{
  white-space: pre-wrap;
  overflow-x: auto;
}

label {
  display: table-cell;
  vertical-align: top;
  padding: 0 .5em .5em;
  min-width: 10%;
  font-weight: bold;
}

input, select{
  flex-grow: 1;
}

input[type='submit']{
  flex-grow: 0;
}

.action-btn{
  padding: 5px;
  background-color: $accent-color;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-right: 3px;
}

.action-btn:hover{
  text-decoration: underline;
}

div.multiform-navigation{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  display: block;
  overflow: auto;
}

div.row.multiform-navigation button{
  margin-right: 5px !important;
}

div.subFormPart{
  margin-top: 15px;
}

div.progressArea{
  text-align: center;
  padding: 10px;
}

form h4, .race-information h4{
  color: $accent-color;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid $accent-color
}

.btn-theme{
  color: white;
  background-color: $accent-color;
  border: 1px solid $accent-color;
  border-radius: 3px;
}

.btn-theme:hover{
  color: $accent-color;
  background-color: white;
}

#select-riders, #select-causes{
  padding: 15px;
  background-color: rgba($accent-color, .10);
  border-radius: 3px;
  margin-bottom: 10px;
}

div.incident-actions>*{
  margin-right: 5px;
}

div.loginform{
  display: flex;
  justify-content: center;
}

div.loginform form{
  width: 75%;
}


div.App{

}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  line-height: $footer-height;
  background-color: #f5f5f5;
}

footer>*{
  margin:0;
}

h1,h2,h3,h4,h5{
  color: $accent-color;
}

h4{
  font-size: large;
}

h3{
  font-size: large;
}

h5{
  font-size: medium;
}

h6{
  font-size: medium !important;
}

div.MuiPaper-root{
  margin-bottom: 10px;
}

table.incidentCauses, table.involvedRiders{
  margin-bottom: 10px;
}

ul.raceDetails{
  list-style-type: none;
}

.btn-nav{
  margin-left:5px;
  margin-right: 5px;
  color: white;
  font-size: small;
}

.btn-nav:hover{
  color:white;
}

.toggle-bar{
  display: inline;
  color: white;
}

.toggle-item{
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
  border-radius: 4px;
  background-color: rgb(148, 148, 148);
  padding: 5px;
  cursor: pointer;
}

.incidents-overview{
  display: block;
  min-height: 30px;
}

#race-incidents-table{
  margin-top: 5px;
}
#race-incidents-searchbar{
  border-radius: 5px;
  border: 1px solid gray;
  padding: 4px;
      padding-left: 4px;
  padding-left: 15px;
}

ul.person-overview{
  display: flex;
  list-style: none;
  margin:0;
  padding: 0;
  font-weight: bold;
}

ul.person-overview img{
  border-radius: 5px;
}

li.person-item{
  width: 200px;
  display: flex;
  flex-direction: column;
}

li.person-item div{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 7px;
}

section.messages{
  border-radius: 3px;
}

.msg,form.msg-form {
  display: flex;
}

.messages, blockquote {
  padding: .5em;
}

.messages,input {
  background-color: rgba($secondary-color, 0.1);
}

input.msg-input {
  padding-left: .325em;
  border: none;
  color: white;
  flex: 1;
}

.msg {
  position: relative;
  margin-bottom: 2em;
}

.msg--date {
  margin-top: 0;
  font-size: 1em;
  font-weight: normal;
  text-align: center;
  background: #222;
  color: #727b80;
}

blockquote.msg-blockquote {
  border-radius: .35em;
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 60%;
}

.msg--them blockquote.msg-blockquote::after, .msg--me blockquote.msg-blockquote::before {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
}

.msg--them blockquote.msg-blockquote {
background-color: #FFBB73;
}

.msg--them blockquote.msg-blockquote::after {
  border: .5em solid #FFBB73;
  border-color: #FFBB73 transparent transparent #FFBB73;
  top: .5em;
  right: -1em;
}

.msg--me blockquote.msg-blockquote {
background-color: #aae5fa; margin-left: 1em;
}

.msg--me blockquote.msg-blockquote::before {
  border: .5em solid #aae5fa;
  border-color: #aae5fa #aae5fa transparent transparent;
  top: .5em;
  left: -1em;
}

form.msg-form{
  background-color: rgba($secondary-color, 0.5) !important; padding: 1px;
  color: white !important;
}


.messages {
flex: 1; overflow: auto;
}

.msg--them {
justify-content: flex-end; 
}

.msg--them blockquote.msg-blockquote {
  order: -1;
  margin-right: 1em;
}

.msg-send {
  padding: 5px;
}

.msg-date{
  float: right;
}
.msg-body{
  padding-bottom: 5px;
}

.msg-date{
  font-size: small;
}

.msg-actions{
  display: inline-flex;
  clear: both;
  width: 100%;
  justify-content: flex-end;
}

.poster-info{
  font-size: x-small;
  text-align: right;
  font-style: italic;
  color: rgb(68, 68, 68);
}

div.message{
  width: 100%;
  text-align: center;
  font-size: x-large;
  background: rgb(197, 250, 197);
  border-radius: 3px;
  margin-bottom: 5px;
  }

.incidentCauseDescription{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.incidentCauseDescriptionContent{
  flex: 0 0 80%;
}

.incidentCauseDescriptionActions .btn{
  margin-right: 2px;
}

.imgThumbContainer{
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.imgThumb{
  position: relative;
}

.imageDeleteBtn{
  display: none;
}

.imgThumb:hover .imageDeleteBtn{
  display: inline;
  position: absolute;
  top: 0;
  right: 0;
}

.imgThumb img{
  height: 200px;
  width: auto;
}

.incidentMediaLinkContainer{
  list-style: none;
  display: flex;
  flex-direction: row;
}

.incidentMediaLink{
  height: 50px;
  width: 50px;
  text-align: center;
  position: relative;
}

.linkDeleteBtn{
  display: none;
}

.incidentMediaLink:hover .linkDeleteBtn{
  display: inline;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.linkDeleteBtn span{
  padding: 0px;
}
.linkDeleteBtn svg{
  font-size: xx-small;
  width: 15px;
  height: 15px;
}

.incidentMediaContainer h5{
  margin-top: 5px;
  margin-bottom: 5px;
}

select[name='incident_rider_implications']{
  width: 80%;
}

.disable-edit{
  width: 18%;
}

.riderInjuryType.editable{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.riderInjuryInput{
  width: 80%;
}

.riderInjuryButtons{
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.riderInjuryButtons .btn{
  margin: 2px;
  padding: 5px;
}

.riderInjuryButtons .btn svg{
  width: 15px;
  height: 15px;
  font-size: x-small;
}

.descriptionBtnArea, .severityBtnArea, .summaryBtnArea, .SAFERcommentBtnArea, .SAFERactionBtnArea, .numberRidersBtnArea, .incidentRemainingDistanceBtnArea, .incidentLocationLatitudeBtnArea, .incidentLocationLongitudeBtnArea{
  display: none;
}

.incidentDescriptionHoverArea, .incidentSeverityHoverArea, .incidentSummaryHoverArea, .incidentSAFERcommentHoverArea, .incidentSAFERactionHoverArea, .incidentNumberRidersHoverArea, .incidentRemainingDistanceHoverArea, .incidentLocationLatitudeHoverArea, .incidentLocationLongitudeHoverArea {
  cursor: pointer;
}

.incidentDescriptionHoverArea:hover span.descriptionBtnArea,
.incidentSeverityHoverArea:hover span.severityBtnArea,
.incidentNumberRidersHoverArea:hover span.numberRidersBtnArea,
.incidentSummaryHoverArea:hover span.summaryBtnArea,
.incidentSAFERcommentHoverArea:hover span.SAFERcommentBtnArea,
.incidentSAFERactionHoverArea:hover span.SAFERactionBtnArea,
.incidentRemainingDistanceHoverArea:hover span.incidentRemainingDistanceBtnArea,
.incidentLocationLatitudeHoverArea:hover span.incidentLocationLatitudeBtnArea,
.incidentLocationLongitudeHoverArea:hover span.incidentLocationLongitudeBtnArea{
  display: inline;
}

p.incidentSeverity{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

p.incidentNumberRiders{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

p.incidentLocationLatitude, p.incidentLocationLongitude{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.incidentLocationLatitudeEdit, .incidentLocationLatitudeInput{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.incidentLocationLongitudeEdit, .incidentLocationLongitudeInput{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.incidentRemainingDistanceInput #incident_km_from_finish{
  width: 300px;
}

.incidentRemainingDistanceEdit{
  flex: 0 0 80%;
  align-items: center;
  justify-content: left;
  display: flex;
}

.incidentRemainingDistanceInput{
  display: flex;
  flex-direction: row;
  align-items: center;
}

p.incidentDistanceToGo{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.incidentSeverityEdit, .incidentSeverityInput{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.incidentNumberRidersEdit, .incidentNumberRidersInput{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

span.userTeamCode{
  margin-left: 2px;
  background-repeat: no-repeat;
}

span.userTeamCode.IGD{
  color: transparent;
  background-image: url('./teamlogos/IGD.png');
  background-color: black;
  width: 100px;
  background-size: 100% 1.5em;
}

span.userTeamCode.AST{
  color: transparent;
  background-image: url('./teamlogos/AST.png');
  background-color: black;
  width: 100px;
  background-size: 100% 1.5em;
}

span.userTeamCode.BOH{
  color: transparent;
  background-image: url('./teamlogos/BOH.png');
  background-color: black;
  width: 100px;
  background-size: 100% 1.5em;
}

.nav-item.nav-accent{
}

.nav-item.nav-accent a{
  color: $accent-color !important;
}

.teamGeneralInfo{
  display: flex;
  align-items: center;
  background-color: lightblue;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.teamGeneralInfo .teamName{
  font-size: large;
  font-weight: bold;
  margin-left: 15px;
}

.riderIncidentHeader{
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.filter-team{
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin: 10px;
}